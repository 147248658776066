import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from "../components/Layout"
import '../styles/principalDesk.css'
import AOS from 'aos';
import { useEffect } from 'react'
import Head from '../components/Head';



function PrincipalDesk() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);

  return (
    <div className='overflow-hidden '>
        <Head title="Principal Desk"/>
        <Layout>
            <Container className="px-5">
                <div className='principalDesk '>
                    <header className="sectionHeader halfBorder my-5" as="h1">Principal Desk</header>
                    
                    <div className='row principalDeskSection' >

                        <div className="rightPart mb-5 mb-lg-0" data-aos="fade-right">
                            <div className="principalPicContainer">
                                <div className="principalPic"><h1 title="principal ksss college" className="d-none">administrator ksss college</h1></div>
                            </div>
                        </div>
                        
                        <div className='leftPart ' data-aos="fade-left">
                            <p className=" sectionContent mb-5 pe-lg-5 pt-lg-5" >
                            KSSS PU Science and Commerce College managed by Karnataka
                            Shikshana Seva Samiti, Hubli was founded by His Holiness Dr. Shri
                            Sri Vishwesha Teertha Swamiji, Pejawar Mutt, Udupi. Our Swamiji
                            has a keen interest towards this college and provide guidance,
                            suggestions and inspirations which have made this college grow in
                            leaps and bounds. The students of this college receive a special
                            recognition everywhere. Right from its inception, the college has
                            been receiving excellent results every year. The college provides
                            quality education and it makes every effort in this regard. We
                            believe that providing good education to the students is
                            worshiping of God.
                            <br />
                            <br />
                            Our team of lecturers are highly experienced and dedicated towards
                            providing intensive teaching and help the students to reach their
                            goals. Our aim is not only to provide the best education, and also
                            to instill high disciplinary standards. Besides the quality
                            education it provides ample opportunity to students to bring out
                            their hidden talents. They take part in curricular and non
                            curricular activities too. They are very well trained to face
                            challenges in future and equipped with proper skills.
                            </p>
                        </div>

                        <div className="principalName">
                            <h1 className="  mt-3">Shri Mahendra G A</h1>
                            <h3>Principal</h3>
                            <h4>M.Sc. B.Ed.</h4>
                        </div>
                        
                    </div>
                    <div className="chiefBarcode" ></div>
                    

                </div>
                
            </Container>
        </Layout>
    </div>
  )
}

export default PrincipalDesk



// import React from 'react'
// import { Container } from 'react-bootstrap'
// import Layout from "../components/Layout"
// import '../styles/principalDesk.css'
// import AOS from 'aos';
// import { useEffect } from 'react'


// function PrincipalDesk() {

//     useEffect(() => {
//         AOS.init({
//           duration : 2000
//         });
//         AOS.refresh();
    
//       }, []);

//   return (
//     <div className='overflow-hidden '>
//         <Layout>
//             <Container className="px-5">
//                 <div className='principalDesk '>
//                     <header className="sectionHeader halfBorder my-5 my-lg-1" as="h1">Principal Desk</header>
                    
//                     <div className='row principalDeskSection' >
                        
//                         <div className='leftPart  col-lg-8' data-aos="fade-left">
//                             <p className=" sectionContent mb-5 pe-lg-5 pt-lg-5" >
//                             KSSS PU Science and Commerce College managed by Karnataka
//                             Shikshana Seva Samiti, Hubli was founded by His Holiness Dr. Shri
//                             Sri Vishwesha Teertha Swamiji, Pejawar Mutt, Udupi. Our Swamiji
//                             has a keen interest towards this college and provide guidance,
//                             suggestions and inspirations which have made this college grow in
//                             leaps and bounds. The students of this college receive a special
//                             recognition everywhere. Right from its inception, the college has
//                             been receiving excellent results every year. The college provides
//                             quality education and it makes every effort in this regard. We
//                             believe that providing good education to the students is
//                             worshiping of God.
//                             <br />
//                             <br />
//                             Our team of lecturers are highly experienced and dedicated towards
//                             providing intensive teaching and help the students to reach their
//                             goals. Our aim is not only to provide the best education, and also
//                             to instill high disciplinary standards. Besides the quality
//                             education it provides ample opportunity to students to bring out
//                             their hidden talents. They take part in curricular and non
//                             curricular activities too. They are very well trained to face
//                             challenges in future and equipped with proper skills.
//                             </p>
//                         </div>

//                         <div className="rightPart col-lg-4" data-aos="fade-right">
//                             <div className="principalPicContainer">
//                                 <div className="principalPic"><h1 title="principal ksss college" className="d-none">administrator ksss college</h1></div>
//                             </div>
//                             <h1 className="administratorName text-center mt-3">Shri Mahendra G A</h1>

                            
//                         </div>
//                     </div>
//                     <div className="chiefBarcode" ></div>
                    

//                 </div>
                
//             </Container>
//         </Layout>
//     </div>
//   )
// }

// export default PrincipalDesk
